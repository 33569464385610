<template>
  <div v-if="badminBannerGroups">
    <div v-for="(banners, groupName) in badminBannerGroups" :key="groupName">
      <!-- curated lists only have "fixed banner" types (for now) -->
      <div v-if="banners[0].banner_type == 'fixed'" class="container">
        <div
          v-for="banner in banners"
          :key="banner.display_order"
          class="banner"
          @click="clickTrack('Curated Movie Page', 'Banner', banner.google_analytics_label);"
        >
          <template v-if="banner.banner_id === curatedDetails.banner_id && (!banner.pathname || banner.url_target === true)">
            <a :href="banner.url" target="_blank" rel="noopener">
              <img :src="`/dyn/dla/images/${banner.img_path}`" class="banner-image" />
            </a>
          </template>
          <template v-if="banner.banner_id === curatedDetails.banner_id && banner.pathname">
            <router-link :to="banner.pathname">
              <img :src="`/dyn/dla/images/${banner.img_path}`" class="banner-image" />
            </router-link>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint max-len: 0 */
import md5 from 'md5';
import Badmin from '@/assets/js/services/Badmin';
import BifrostAPI from '@/assets/js/services/Bifrost/API';

export default {
  data() {
    return {
      curatedDetails: {},
      badminBannerGroups: null,
    };
  },
  async created() {
    // md5 salt: changes to this will also need to be updated on the DL Admin Server's curated component worker
    const SALT = 'dM!^F#*3Hb9#G&yhpfr*';

    // get curated info
    const bfAPI = new BifrostAPI();
    const curatedMovies = await bfAPI.getCuratedMovieLists();
    const hashedListName = md5(this.$route.query.list + SALT);
    this.curatedDetails = curatedMovies[hashedListName];

    if (this.curatedDetails.banner_id) {
      // get banner info
      const badminSvc = new Badmin(this.locale, this.userTypeID);
      this.badminBannerGroups = await badminSvc.getBanners('curated');
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
  },
  watch: {
    async userTypeID(newVal, oldVal) {
      if (newVal !== oldVal) {
        const badminSvc = new Badmin(this.locale, this.userTypeID);
        this.badminBannerGroups = await badminSvc.getBanners('curated');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
