<i18n>
{
  "en": {
    "browser_title": "Recommended Videos"
  },
  "ja": {
    "browser_title": "おすすめ動画リスト"
  }
}
</i18n>

<template>
  <main>
    <vue-headful v-bind:title="dynamicTitle" />
    <div id="curated" class="page-margin">
      <curated-image />
      <curated-list />
    </div>
  </main>
</template>

<script>
/* eslint max-len: 0 */
import md5 from 'md5';
import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import CuratedImage from '@/components/curated/curatedImage.vue';
import CuratedList from '@/components/curated/curatedList.vue';

export default {
  components: {
    'vue-headful': vueHeadful,
    'curated-image': CuratedImage,
    'curated-list': CuratedList,
  },
  data() {
    return {
      dynamicTitle: '',
    };
  },
  async mounted() {
    // md5 salt: changes to this will also need to be updated on the DL Admin Server's curated component worker
    const SALT = 'dM!^F#*3Hb9#G&yhpfr*';

    // set browser title
    const bfAPI = new BifrostAPI();
    const curatedMovies = await bfAPI.getCuratedMovieLists();
    const hashedListName = md5(this.$route.query.list + SALT);
    this.curatedDetails = curatedMovies[hashedListName];
    this.dynamicTitle = `${this.curatedDetails.title} ${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/_curated.scss';
</style>
