<i18n>
{
  "en": {
    "heading": "Featured Movies",
    "vip_badge": "VIP",
    "svip_badge": "SVIP",
    "annual_badge": "Annual",
    "limited_tag": "Limited Availability",
    "expired_tag": "Availability Ended",
    "future_tag": "Upcoming"
  },
  "ja": {
    "heading": "動画リスト",
    "vip_badge": "VIP",
    "svip_badge": "超VIP",
    "annual_badge": "年間",
    "limited_tag": "期間限定",
    "expired_tag": "配信期間終了",
    "future_tag": "近日配信"
  }
}
</i18n>

<template>
  <div class="container">
    <div class="section">
      <div class="heading">
        <h1 v-if="locale === 'en'">{{ $t('heading') }}: {{ curatedDetails.title }}</h1>
        <h1 v-else>{{ curatedDetails.title }} {{ $t('heading') }}</h1>
      </div>
      <div class="curated-description">{{ curatedDetails.body }}</div>
    </div>

    <div class="section">
      <div class="grid is-movie">
        <div v-for="movie in curatedDetails.movies" :key="movie.movie_id" class="grid-item">
            <div class="entry" v-observe-visibility="{
              callback: (isVisible, entry) => visibilityChanged(isVisible, entry, movie.movie_date_id),
              once: true, // stop observing after it's become visible
              throttle: 200, // delay until running callback (visibility won't trigger if fast scrolling)
            }">
            <!-- movie grid item; populated if the visibility observer populated movie details for the movie -->
            <template v-if="movieDetails[movie.movie_date_id]">
              <div class="entry-media" :class="{ 'is-play': hasSampleMovie(movieDetails[movie.movie_date_id]) }" @click="showModalSample(movieDetails[movie.movie_date_id]); clickTrack('Curated Page', 'Play Curated Movie', movieDetails[movie.movie_date_id].Title)">
                <div v-if="!newXPEligible && movieDetails[movie.movie_date_id].isVIP" class="tag-member is-vip">{{ $t('vip_badge') }}</div>
                <div v-if="!newXPEligible && movieDetails[movie.movie_date_id].isSVIP" class="tag-member is-svip">{{ $t('svip_badge') }}</div>
                <div v-if="!newXPEligible && movieDetails[movie.movie_date_id].isAnnual" class="tag-member is-annual">{{ $t('annual_badge') }}</div>
                <div class="media-thum">
                  <img class="media-image" :src="`${movieDetails[movie.movie_date_id].MovieThumb}`" @error="useFallbackImage(movie.movie_date_id)" loading="lazy" alt="">
                </div>
              </div>
              <component :is="isFutureMovie(movieDetails[movie.movie_date_id]) ? 'div' : 'router-link'" :to="'' || `/movies/${movie.movie_date_id}/`" class="entry-meta">
                <div v-if="movieDetails[movie.movie_date_id].IsExpired" class="tag-status is-expired">{{ $t('expired_tag') }}</div>
                <div v-if="movieDetails[movie.movie_date_id].IsLimited" class="tag-status is-limited">{{ $t('limited_tag') }}</div>
                <div v-if="movieDetails[movie.movie_date_id].IsComingSoon" class="tag-status is-future">{{ $t('future_tag') }}</div>
                <div class="meta-data">{{ movieDetails[movie.movie_date_id].ReleaseDate }}<span v-if="movieDetails[movie.movie_date_id].ExpireDate"> &ndash; {{ movieDetails[movie.movie_date_id].ExpireDate }}</span></div>
                <template v-if="locale == 'ja'">
                  <div class="meta-title">{{ movieDetails[movie.movie_date_id].Title }}</div>
                  <div class="meta-data">{{ movieDetails[movie.movie_date_id].Actor }}</div>
                </template>
                <template v-if="locale == 'en'">
                  <div v-if="movieDetails[movie.movie_date_id].TitleEn != null && movieDetails[movie.movie_date_id].TitleEn != ''" class="meta-title">{{ movieDetails[movie.movie_date_id].TitleEn }}</div>
                  <div class="meta-data">
                    <span v-for="(actress, index) in movieDetails[movie.movie_date_id].ActressesEn" :key="actress.$index">
                      {{ actress }}<span v-if="index + 1 < movieDetails[movie.movie_date_id].ActressesEn.length">,&nbsp;</span>
                    </span>
                  </div>
                </template>
              </component>
            </template>
            <!-- placeholder movie grid item; used if there are no details fetched yet (not visible) -->
            <template v-else class="entry-media">
              <div class="media-placeholder"></div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint max-len: 0 */
/* eslint no-shadow: 0 */
import md5 from 'md5';
import MovieHelper from '@/assets/js/utils/movie';
import BifrostAPI from '@/assets/js/services/Bifrost/API';

const USE_PORTRAIT_POSTER = true;
const USE_LARGE_POSTER_FALLBACK = false;

export default {
  data() {
    return {
      curatedDetails: {},
      movieDetails: {}, // keyed by movie id
      totalMovies: 0,
      posterFallbackTries: {},
    };
  },
  async created() {
    // md5 salt: changes to this will also need to be updated on the DL Admin Server's curated component worker
    const SALT = 'dM!^F#*3Hb9#G&yhpfr*';

    // get curated info
    const bfAPI = new BifrostAPI();
    const curatedMovies = await bfAPI.getCuratedMovieLists();
    const hashedListName = md5(this.$route.query.list + SALT);
    this.curatedDetails = curatedMovies[hashedListName];
    this.totalMovies = curatedMovies[hashedListName].movies.length;
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
  },
  methods: {
    getMovieDetail(movieDateID) {
      const bfAPI = new BifrostAPI();
      if (!Object.prototype.hasOwnProperty.call(this.movieDetails, movieDateID)) {
        bfAPI.getMovieDetail(movieDateID).then((detail) => {
          const movieDetailExpanded = MovieHelper.expandDetails(
            detail,
            this.locale,
            this.userTypeID,
          );
          this.$set(this.movieDetails, movieDateID, movieDetailExpanded);
        });
      }
    },

    useFallbackImage(movieDateID) {
      // missing movie poster thumbnail, fallback to large poster or placeholder
      if (USE_LARGE_POSTER_FALLBACK && !this.posterFallbackTries[movieDateID]) {
        // attempt 1: poster thumbnail is missing, use large poster
        this.$set(
          this.movieDetails[movieDateID],
          'MovieThumb',
          `/assets/sample/${movieDateID}/str.jpg`,
        );
        this.posterFallbackTries[movieDateID] = true;
      } else {
        // attempt 2: large poster is missing, use no-image placeholder
        this.$set(
          this.movieDetails[movieDateID],
          'MovieThumb',
          (USE_PORTRAIT_POSTER) ? '/img/common/now_printing_portrait.png' : '/img/common/now_printing.png',
        );
      }
    },

    isFutureMovie(movie) {
      return (!movie.Status);
    },

    hasSampleMovie(movie) {
      return (Object.prototype.hasOwnProperty.call(movie, 'SampleFiles'));
    },

    showModalSample(movie) {
      if (this.hasSampleMovie(movie)) this.$modal.show('ui-modal-video', { movieId: movie.MovieID });
    },

    visibilityChanged(isVisible, entry, movieDateID) {
      if (isVisible) this.getMovieDetail(movieDateID);
    },

    clickTrack(category, action, label = null, value = null) {
      this.$analytics.trackEvent(category, action, label, value);
    },
  },
};
</script>

<style lang="scss" scoped>
.curated-description {
  white-space: pre-line;
}
.media-placeholder {
  height: 420px;
}
</style>
